<template>
  <el-card class="sm:px-0">
    <ul v-if="ordersList.length > 0" class="-my-20">
      <li
        v-for="(order, idx) in ordersList"
        :key="idx"
        class="bg-white hover:bg-grey-50 py-16 px-16 sm:px-24 border-b border-transparent cursor-pointer"
        :class="{ 'border-hr': idx !== ordersList.length - 1, 'bg-grey-50': order.isMarked }"
        @click="onRowClick(order)"
      >
        <div class="flex flex-1 items-center justify-between mb-8">
          <div class="flex content-start">
            <el-checkbox v-model="order.isMarked" :disabled="order.fulfillmentStatus === 'Fulfilled' || order.fulfillmentStatus === 'Refunded'" />
            <p class="text-green-500 font-medium ml-20">
              {{ order.name }}
            </p>
            <p class="font-thin text-xs ml-64">
              <span
                v-for="(line_item, lIdx) in getLineItems(order.line_items)"
                :key="lIdx"
              >
                <span v-if="lIdx > 0 && lIdx < order.line_items.length"> / </span> {{ line_item.quantity }} x {{ line_item.title }}
              </span>
            </p>
          </div>

          <el-status :status="order.fulfillmentStatus" />
        </div>

        <div class="sm:flex sm:items-center sm:justify-between">
          <div class="flex items-center mr-24 mb-8 sm:mb-0 ml-32">
            <span class="icon-user flex justify-start text-2lg text-grey-300" />
            <p class="ml-6 text-grey-400">{{ getFullName(order.shopifyCustomer) }}</p>
          </div>

          <div class="flex items-center ml-32 sm:ml-0">
            <span class="icon-calendar flex justify-start text-2lg text-grey-300" />
            <span class="ml-6 inline-block text-grey-400">
              Oprettet
              <time :datetime="getDateTime(order.created_at)">
                {{ order.created_at | formatDate }} - {{ order.created_at | formatTime }}
              </time>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </el-card>
</template>

<script>
const ElCard = () => import('@/components/shared/ELCard')
const ElStatus = () => import('@/components/shared/ELStatus')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'OrdersList',

  components: { ElCard, ElStatus, ElCheckbox },

  props: {
    ordersList: {
      type: Array,
      default: () => []
    },

    isRowClick: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    getLineItems (lineItems) {
      return lineItems.filter(lineItem => {
        return !this.isFee(lineItem.title)
      })
    },

    isFee (lineItem) {
      const HANDLING_FEE_PRODUCT_TITLE = 'Håndteringsgebyr'
      const SHIPPING_FEE_PRODUCT_TITLE = 'Forsendelse'
      const TICKET_FEE_PRODUCT_TITLE = 'Billetgebyr'
      const feeList = [HANDLING_FEE_PRODUCT_TITLE, SHIPPING_FEE_PRODUCT_TITLE, TICKET_FEE_PRODUCT_TITLE]
      return feeList.indexOf(lineItem) > -1
    },

    getFullName (data) {
      return data ? `${data.first_name} ${data.last_name}` : ''
    },

    getDateTime (createdAt) {
      return new Date(createdAt).toLocaleDateString()
    },

    onRowClick (order) {
      if (this.isRowClick) {
        return this.$router.push({ name: 'Order', params: { orderId: order.id } })
      }
      if (order.fulfillmentStatus !== 'Fulfilled' && order.fulfillmentStatus !== 'Refunded') {
        order.isMarked = !order.isMarked
      }
    }
  }
}
</script>
